<template>
    <form-card card-title="Record Daily Attendance" card-sub-title="" class="bg-light">
        <form v-on:submit.prevent="attendanceForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div>
                            <label>Select Attendance</label>
                            <select class="form-control" v-model="form.present">
                                <option value="" disabled selected hidden>Select Yes/No (Present/Absent)</option>
                                <option v-for="option in choices" :value="option" :key="option">{{option}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center">
                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Save Record</button>
            </div>
        </form>
    </form-card>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import NoButtonWhiteCardVue from '../../../components/Cards/NoButtonWhiteCard.vue'
export default {
  name: 'AttendanceForm',
  components:{
      'form-card': NoButtonWhiteCardVue
  },
  props:[
      'employeeId'
  ],
  computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
  data(){
      return{
          choices: ['yes','no'],
          form:{
              employee_id: this.employeeId,
              present: '',
          }
      }
  },
  methods:{
        attendanceForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/production/attendances', this.form,{ 
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.form.present = ''
                this.$emit('success',true)
                
            })
            .catch((error) => {
            // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        }
  }
}
</script>