<template>
    <div>
        <stats-card card-sub-title="" card-title="Timesheet Reports" class="bg-white text-dark"> 
            <h5 class="text-white mt-2 bg-success p-2 text-uppercase">EMPLOYEE NAME: <span class="text-yellow">{{fullName}}</span></h5>
            <div class="row">
                <div class="col-6 card">
                    Total days <em class="text-green">Present this month</em><br>
                    {{totalMonthPresent}}
                </div>
                <div class="col-6 card">
                    Total days <em class="text-red">Absent this month</em><br>
                    <span v-if="records">{{records.length - totalMonthPresent}}</span>
                </div>
                <div class="col-6 card">
                    Salary Payable <em class="text-blue">Due this month</em><br>
                    ₦ {{salaryDue}}
                </div>
            </div>
            <ul v-if="records">
               <li v-for="record in records" :key="record.attendance_id">
                   <span class="text-green" v-if="record.present === 'yes'">Present</span><span class="text-red" v-else>Absent</span> on the <span class="badge badge-primary">{{record.created_at_date}}</span>

                   <span class="text-right">
                       <button type="button" @click="deleteRecord(record.attendance_id)" class="btn btn-sm"><i class="fas fa-times text-red"></i> </button>
                   </span>
               </li>
            </ul>
            <div v-else> Nothing to see</div>
        </stats-card>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import StatsCardVue from '../../../components/Cards/StatsCard.vue';
export default {
    name: 'TimesheetReports',
    props:[
        'employeeId', 'fullName', 'charge'
    ],
    computed: {
        ...mapState({
            authToken : state => state.authToken
        }),
        totalMonthPresent(){
            if (this.records) {
                let myCount = 0
                for (let i = 0; i < this.records.length; i++ ) {
                    let element = this.records[i]
                    if (element.present === 'yes') {
                        myCount += 1
                    } 
                }
                return myCount
            }
            else {
                return 0
            }
        },
        salaryDue(){
            if (this.totalMonthPresent) {
                return this.charge * this.totalMonthPresent
            }
            else {
                return 0
            }
        }
    },
    data(){
        return{
            records: []
        }
    },
    components:{
        'stats-card':StatsCardVue
    },
    mounted(){
        this.fetchRecords()        
    },
    methods:{
        fetchRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/attendances?employee_id='+this.employeeId+'&month=yes',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },

        deleteRecord(record){
            if(confirm("Do you really want to delete?")){
                axios.delete(process.env.VUE_APP_BASEURL+'/admin/production/attendances/'+record+'?confirm=yes',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                .then(() => {
                    this.$moshaToast('Deleted Successfully!!',{
                        type: 'success'
                    });
                    this.fetchRecords()
                })
                .catch((error) => {
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                })  
            }
        },
    }
}
</script>
<style scoped>
ul{
    margin-left: 0;
    padding-inline-start: 5px;
}
li{
    border-bottom: 1px solid rgb(248, 128, 128);
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 13px;
}
.badge-danger, .badge-primary{
    color:#000;
}
</style>